#portfolio-flters .btn {
    position: relative;
    display: inline-block;
    margin: 10px 4px 0 4px;
    transition: .5s;
}

#portfolio-flters .btn:hover,
#portfolio-flters .btn.active {
    color: var(--light);
    background: var(--primary);
}

.portfolio-item:hover .portfolio-overlay {
    opacity: 1;
}

#partners-list {
    display: none;
}