/* Loading.module.css */

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F3F6FF;
}

.spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-text {
    margin-top: 15px;
    font-size: 1.25rem;
    font-weight: bold;
}