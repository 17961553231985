.form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-form {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    width: 400px;
}

.login-form h2 {
    margin-bottom: 20px;
}

.login-form label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.login-form input {
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
}

.login-form button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    transition: all 0.3s;
    width: 100%;
}

.login-form button:hover {
    background-color: #0069d9;
}

.form-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

.login-form2 {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    text-align: center;
    width: 80%;
}

.login-form2 h2 {
    margin-bottom: 20px;
    font-size: 60px;
}

.login-form2 label {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    font-size: 30px;
}

.login-form2 input {
    border: #007bff;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-bottom: 20px;
    width: 100%;
    font-size: 60px;
}

.login-form2 button {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 10px 20px;
    transition: all 0.3s;
    width: 100%;
    font-size: 40px;
}

.login-form2 button:hover {
    background-color: #0069d9;
}