.company-cover-img {
    max-height: 200px;
    position: relative;
}

.company-cover-img img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.avatar-wrapper {
    width: 136px;
    height: 136px;
    border-radius: 50%;
    overflow: hidden;
}

.avatar-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}