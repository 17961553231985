.header-bar {
    height: 60px;
}

.left-bar {
    display: flex;
    flex-direction: column;
    width: 200px;
}

main {
    flex: 1;
}